import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

const PageNotFound = () => {
  return (
    <>
        <Header />
        <div className="section text-center">
            <h1>404 Error | Page Not Found</h1>
        </div>
        <Footer />
    </>
  )
}

export default PageNotFound