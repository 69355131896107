import React from 'react'
import './Choose.css'
import Image1 from '../../assets/choose-image1.png'
import Image2 from '../../assets/choose-image2.png'
import Image3 from '../../assets/choose-image3.png'
import Image4 from '../../assets/choose-image4.png'
import CheckIcon from '../../assets/check.png'

const Choose = () => {
  return (
    <div className="section why-choose-section">
        <div className="choose-left">
            <img src={Image1} />
            <img src={Image2} />
            <img src={Image3} />
            <img src={Image4} />
        </div>
        <div className="choose-right">
            <h4 className="short-hint">Some Reason</h4>
            <h2 className="section-title text-left">
                <span className="stroke">Why </span>
                <span>Choose Us?</span>
            </h2>
            <p className="choose-list">
                <img src={CheckIcon} />
                <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</span>
            </p>
            <p className="choose-list">
                <img src={CheckIcon} />
                <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</span>
            </p>
            <p className="choose-list">
                <img src={CheckIcon} />
                <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</span>
            </p>
            <p className="choose-list">
                <img src={CheckIcon} />
                <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</span>
            </p>
            <p className="choose-list">
                <img src={CheckIcon} />
                <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</span>
            </p>
        </div>
    </div>
  )
}

export default Choose