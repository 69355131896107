import React from 'react'
import './Contact.css'
import Call from '../../assets/phone-call.png'
import Mail from '../../assets/email.png'
import Location from '../../assets/location.png'
import Web from '../../assets/web.png'
import InnerHero from '../InnerPageHero/InnerHero'
import Heroimage from '../../assets/hero_image.png'

const Contact = () => {
  const heroData={
    titlestroke:"Stroke",
    titlefill:"Fill title heading",
    herotext:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid unde laborum temporibus tempore doloribus deleniti officia.",
    heroimage:Heroimage
  }
  return (
    <>
      <InnerHero hero={heroData} />
      <div className="section contact-section">
        <div className="contact-left">
            <h1 className="section-title">
              <span className="stroke">Enquiry </span>
              <span>Form</span>
            </h1>
            <hr />
          <form>
            <div className="form-group">
              <input type="text" name="name" placeholder="Enter Name" className="form-control" />
            </div>
            <div className="form-group">
              <input type="email" name="email" placeholder="Enter Email Address" className="form-control" />
            </div>
            <div className="form-group">
              <input type="text" name="number" placeholder="Enter Contact Number" className="form-control" />
            </div>
            <div className="form-group">
              <textarea name="message" placeholder="Enter Message" rows="5" >
              </textarea>
            </div>
            <div className="form-group">
              <button className="btn-primary">Submit</button>
            </div>
          </form>
        </div>
        <div className="contact-right">
          <h2 className="section-title">
            <span className="stroke">Contact </span>
            <span>Information</span>
          </h2>
          <hr />
          <div className="contact-details">
            <p>
              <span>
                <img src={Call} />
              </span>
              <span><a href="tel:+91-9991627209">+91-9991627209</a></span>
            </p>
            <p>
              <span>
                <img src={Mail} />
              </span>
              <span><a href="mailto:info@metropoliscoder.com">info@metropoliscoder.com</a></span>
            </p>
            <p>
              <span>
                <img src={Location} />
              </span>
              <span>Section 28, Gurgaon - 122003</span>
            </p>
            <p>
              <span>
                <img src={Web} />
              </span>
              <span><a href="http://metropoliscoder.com">metropoliscoder.com</a></span>
            </p>
          </div>
        </div>
      </div>      
    </>
  )
}

export default Contact