import React from 'react'
import Header from '../Header/Header'
import Hero from '../Hero/Hero'
import Programs from '../Programs/Program'
import Choose from '../Choose/Choose'
import Plan from '../Plan/Plan'
import Testimonials from '../Testimonials/Testimonials'
import Newsletter from '../Newsletter/Newsletter'
import Footer from '../Footer/Footer'

const HomePage = () => {
  return (
    <>
        <Header />
        <Hero />
        <Programs />
        <Choose />
        <Plan />
        <Testimonials />
        <Newsletter />
        <Footer />
    </>
  )
}

export default HomePage