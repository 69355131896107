import React, {useState} from 'react'
import './Header.css'
import Bars from '../../assets/bar.png'
import Cross from '../../assets/cross.png'
import { Outlet ,Link } from 'react-router-dom'

const Header = () => {
    const [menuOpen,setMenu]=useState(false)
    const mobile=window.innerWidth<=768? true :false
  return (
    <div className="menu">
        <div className="left-menu">
            <h3 className="menu-brand">
                <Link to="/">Milaap</Link>
            </h3>
        </div>
        <div className="right-menu">
            {(menuOpen===false && mobile==true)?
                <img src={Bars} className="bar-image"
                onClick={()=>setMenu(true)}
                />
                :
                <div>
                    {(mobile)? <img src={Cross} className="close-menu"
                    onClick={()=>setMenu(false)}
                    /> : ''}
                    
                    <ul className="menu-list">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/services">Services</Link>
                        </li>
                        <li>
                            <Link to="/plans">Plans</Link>
                        </li>
                        <li>
                            <Link to="/testimonials">Testimonials</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li onClick={()=>setMenu(false)}>
                            <button href="" className="btn-primary">Join Now</button>
                        </li>
                    </ul>
                </div>
            }            
        </div>
        <Outlet />
    </div>
  )
}

export default Header