import React from 'react'
import './Program.css'
import { programsData } from '../../Data/programData'
import rightArrow from '../../assets/rightArrow.png'

const Program = () => {
  return (
    <div className="section pt30 program-section">
        <h2 className="section-title text-center">
            <span className="stroke">EXPLORE OUR </span>
            <span className="special-space">PROGRAMS</span>
            <span className="stroke">TO SHAPE YOU</span>
        </h2>
        <div className="category">
            {
                programsData.map((program)=>(                   
                    <div className="program-card" key={program.heading}>
                        {program.image}
                        <h3 className="program-heading">{program.heading}</h3>
                        <p className="program-detail">{program.details}</p>
                        <div className="learn-more">
                            <a href="">
                                <span>Learn More</span>
                                <img src={rightArrow}  />
                            </a>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Program