import React from 'react'
import './Newsletter.css'

const Newsletter = () => {
  return (
    <div className="section newsletter-section">
        <hr />
        <div className="newsletter-left">
            <h2 className="section-title">
                <span className="stroke">READY TO </span>
                <span>LEVEL UP YOUR BODY </span>
                <span className="stroke">WITH US?</span>
            </h2>
        </div>
        <div className="newsletter-right">
            <div className="newsletter-email">
                <input type="email" name="email" placeholder="Enter Your Email Address" />
                <button className="btn-primary">Join Now</button>
            </div>
        </div>
    </div>
  )
}

export default Newsletter