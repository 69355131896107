import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Contact from '../Contact/Contact'

const ContactPage = () => {
  return (
    <>
        <Header />
        <Contact />
        <Footer />
    </>
  )
}

export default ContactPage