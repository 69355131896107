import React from 'react'
import './Hero.css'
import Heroimage from '../../assets/hero_image.png'
import Herobackimage from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import { motion } from 'framer-motion'
import NumberCounter from 'number-counter';

const Hero = () => {
    const transtion={type:'spring', duration:3}
    const mobile=window.innerWidth<=768 ? true :false;
  return (
    <div className="hero-section">
        <div className="blur hero-blur"></div>
        <div className="hero-left">
            <div className="highlight">
                <motion.div className="highlight-mark"
                initial={{left:mobile? "165px" : "298px"}}
                whileInView={{left:"9px"}}
                    transition={{...transtion,type:'tween'}}
                ></motion.div>
                <span>The highlight line will be come here.</span>
            </div>
            <h1 className="hero-title">
                <span className="stroke">SHAPE </span>
                <span>Your</span>
                <br />
                <span>IDEAL BODY</span>
            </h1>
            <p className="hero-text">In here we will help you to shape and build your ideal body and live up your life to fullest</p>
            <div className="hero-parameter">
                <div className="hero-parameterdiv">
                    <p className="parameter-number">
                        <NumberCounter end={140} start={100} delay="2" preFix="+" /></p>
                    <p className="parameter-title">EXPERT COACHES</p>
                </div>
                <div className="hero-parameterdiv">
                    <p className="parameter-number">
                        <NumberCounter end={50} start={30} delay="2" preFix="+" />
                    </p>
                    <p className="parameter-title">MEMBERS JOINED</p>
                </div>
                <div className="hero-parameterdiv">
                    <p className="parameter-number">
                        <NumberCounter end={100} start={80} delay="2" preFix="+" />
                    </p>
                    <p className="parameter-title">FITNESS PROGRAMS</p>
                </div>
            </div>
            <div className="hero-button">
                <button className="btn-primary">Get Started</button>
                <button className="btn-transparent">Know More</button>
            </div>
        </div>
        <div className="hero-right">
            <motion.div
            initial={{right:"1rem"}}
            whileInView={{right:"3rem"}}
            transition={transtion}
            className="heart-card">
                <img src={Heart} />
                <p className="parameter">100+</p>
                <h5>Solve Cases</h5>
            </motion.div>
            <img src={Heroimage} className="hero-image" />
            <motion.img
            initial={{right:"11rem"}}
            whileInView={{right:"20rem"}}
            transition={transtion}
            src={Herobackimage} className="hero-backimage" />
        </div>
    </div>
  )
}

export default Hero