import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Testimonials from '../Testimonials/Testimonials'

const TestimonialsPage = () => {
  return (
    <>
        <Header />
        <Testimonials />
        <Footer />
    </>
  )
}

export default TestimonialsPage