import React from 'react'
import './InnerHero.css'
import Herobackimage from '../../assets/hero_image_back.png'
import { motion } from 'framer-motion'

const InnerHero = (props) => {
    const transtion={type:'spring', duration:3}
    const hero=props.hero
  return (
    <div className="innerhero-section">
        <div className="innerhero-left">
            <div className="hero-content">
                <h1 className="hero-title">
                    <span className="stroke">{hero.titlestroke} </span>
                    <span>{hero.titlefill}</span>
                </h1>
                <p className="hero-text">{hero.herotext}</p>
            </div>
        </div>
        <div className="innerhero-right">
            <img src={hero.heroimage} className="innerhero-image" />
            <motion.img
            initial={{right:"11rem"}}
            whileInView={{right:"20rem"}}
            transition={transtion}
            src={Herobackimage} className="innerhero-backimage" />
        </div>
    </div>
  )
}

export default InnerHero