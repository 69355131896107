import React from 'react'
import './Footer.css'
import Insta from '../../assets/insta.png'
import Linkedin from '../../assets/linkedin.png'

const Footer = () => {
  return (
    <div className="section m-0 footer-section">
        <div className="blur footer-blur"></div>
        <hr/>
        <div className="footer">
            <h2 className="footer-logo">Milaap</h2>
            <ul className="footer-social">
                <li>
                    <img src={Insta} />
                </li>
                <li>
                    <img src={Linkedin} />
                </li>
                <li>
                    <img src={Insta} />
                </li>
                <li>
                    <img src={Linkedin} />
                </li>
            </ul>
            <ul className="footer-menu">
                <li>About</li>
                <li>Services</li>
                <li>Testimonials</li>
                <li>Contact</li>
            </ul>
        </div>
        <div className="footer-bottom">
            <p>&copy; {new Date().getFullYear()}, All Reserved by <span className="primary-color">Milaap</span> | Design &amp; Developed by <a href="#">Metropolis Coder</a></p>
            <ul className="footer-terms">
                <li>Privacy Policy</li>
                <li>Terms &amp; Conditions</li>
                <li>Sitemap</li>
            </ul>
        </div>
    </div>
  )
}

export default Footer