import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './components/Pages/HomePage';
import AboutPage from './components/Pages/AboutPage'
import ServicesPage from './components/Pages/ServicesPage'
import PlansPage from './components/Pages/PlansPage'
import TestimonialsPage from './components/Pages/TestimonialsPage'
import ContactPage from './components/Pages/ContactPage'
import PageNotFound from './components/Pages/PageNotFound';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />        
          <Route path="/plans" element={<PlansPage />}  />
          <Route path="/testimonials" element={<TestimonialsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
