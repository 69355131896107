import React from 'react'
import Choose from '../Choose/Choose'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

const AboutPage = () => {
  return (
    <>
        <Header />
        <Choose />
        <Footer />
    </>
  )
}

export default AboutPage