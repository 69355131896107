import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Plan from '../Plan/Plan'

const PlansPage = () => {
  return (
    <>
        <Header />
        <Plan />
        <Footer />
    </>
  )
}

export default PlansPage