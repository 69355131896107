import React, {useState} from 'react'
import './Testimonials.css'
import { testimonialsData } from '../../Data/testimonialsData'
import leftArrow from '../../assets/left-arrow.png'
import rightArrow from '../../assets/right-arrow.png'
import { motion } from 'framer-motion'

const Testimonials = () => {
    const [testimonial,setTestimonial]=useState(0);
    const testimonialLength=testimonialsData.length
    const transtion={type:'spring', duration:3}
  return (
    <div className="section testimonials-section">
        <div className="testimonials-left">
            <h4 className="short-hint">Testimonials</h4>
            <h2 className="section-title">
                <span className="stroke">WHAT THEY</span><br />
                <span>SAY ABOUT US</span>
            </h2>
            <div className="testimonial">
                <p className="testimonial-review">{testimonialsData[testimonial].review}</p>
                <p className="testimonial-footer">
                    <span className="testimonial-name">{testimonialsData[testimonial].name}</span> - 
                    <span className="testimonial-status"> {testimonialsData[testimonial].status}</span>
                </p>
            </div>
        </div>
        <div className="testimonials-right">
            <div className="testimonials-arrow">
                <img src={leftArrow} 
                onClick={() => { 
                    testimonial === 0 
                    ? setTestimonial(testimonialLength - 1) 
                    : setTestimonial(testimonial - 1) 
                }} />
                <img src={rightArrow} 
                    onClick={() => {
                        testimonial===testimonialLength - 1
                        ? setTestimonial(0)
                        : setTestimonial(testimonial + 1)
                    }}
                />
            </div>
            <div className="testimonials-image">
                <motion.div 
                    initial={{opacity:0,x:"-100"}}
                    whileInView={{opacity:1,x:0}}
                    transition={{...transtion,duration:2}}
                className="testimonial-border"></motion.div>
                <motion.div
                    initial={{opacity:0,x:"100"}}
                    whileInView={{opacity:1,x:0}}
                    transition={{...transtion,duration:2}}
                className="testimonial-back-box"></motion.div>
                <motion.img
                    key={testimonial}
                    initial={{opacity:0,x:100}}
                    animate={{opacity:1,x:0}}
                    exit={{opacity:0,x:-100}}
                    transition={transtion}
                src={testimonialsData[testimonial].image} />
            </div>
        </div>
    </div>
  )
}

export default Testimonials