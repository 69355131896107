import React from 'react'
import {plansData} from '../../Data/plansData'
import './Plan.css'
import Checkwhite from '../../assets/check-white.png'

const Plan = () => {
  return (
    <div className="section">
        <h2 className="section-title text-center">
            <span className="stroke">READY TO START </span>
            <span className="special-space">YOUR JOURNEY </span>
            <span className="stroke">NOW WITH US</span>
        </h2>
        <div className="blur plan-blur-1"></div>
        <div className="blur plan-blur-2"></div>          
        <div className="plans">
          {
            plansData.map((plan,i)=>(
              <div key={i} className="plan-card">
                {plan.icon}
                <h3 className="plan-heading">{plan.name}</h3>
                <h4 className="plan-price">₹ {plan.price}</h4>
                <ul className="plan-feature">
                {plan.features.map((feature,i)=>(
                  <li key={i}>
                    <img src={Checkwhite} />
                    <span>{feature}</span>                    
                  </li>
                ))}
                </ul>
                <button className="btn-join">Subscribe Now</button>
              </div>
            ))
          }
        </div>
    </div>
  )
}

export default Plan