import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Programs from '../Programs/Program'

const ServicesPage = () => {
  return (
    <>
        <Header />
        <Programs />
        <Footer />
    </>
  )
}

export default ServicesPage